import React from "react";
import { Box, Stack } from "@mui/material";
import Svg404PageIcon from "src/assets/icons/404PageIcon";
import SubmitButton from "src/components/common/Buttons/SubmitButton";
import { useRouter } from "next/router";
import paths from "src/router/paths";
import Typography from "@mui/material/Typography";
import { baseUrlGeneration } from "src/utils/helpers/url/baseUrlGeneration";
import { ModalType } from "src/utils/hooks/state/useModal";
import { useModalContext } from "src/components/common/Layout/context/ModalContext";
import Head from "next/head";

const Custom404: React.FC = () => {
  const router = useRouter();

  const { openModal } = useModalContext();

  return (
    <Box width="100%">
      <Head>
        <title>Страница не найдена</title>
      </Head>
      <Stack
        alignItems="center"
        paddingX={2}
        justifyContent="center"
        sx={{ height: "100%" }}
      >
        <Typography
          sx={{ marginBottom: "15px", marginTop: "15px" }}
          variant="h2"
          component="h1"
          textAlign="center"
        >
          Страница не найдена
        </Typography>
        <Typography
          lineHeight="18px"
          variant="subtitle2"
          component="h4"
          textAlign="center"
          maxWidth="600px"
        >
          По запрошенному Вами адресу ничего не было найдено. Возможно, страница
          была удалена или ссылка некорректна. Пожалуйста, попробуйте
          воспользоваться одним из разделов ниже.
        </Typography>
        <Box>
          <Svg404PageIcon style={{ width: "100%" }} />
        </Box>
        <Box maxWidth="600px" alignItems="center" justifyContent="center">
          <Stack spacing={2}>
            <SubmitButton
              onClick={() => router.push(baseUrlGeneration(router))}
              title="Главная"
              height={40}
              sx={{ marginRight: "10px" }}
            />
            <SubmitButton
              onClick={() => openModal(ModalType.SEARCH)}
              title="Поиск по каталогу"
              height={40}
              sx={{ marginRight: "10px" }}
            />
            <SubmitButton
              onClick={() => router.push(paths.FAQ)}
              title="Вопросы и ответы"
              height={40}
            />
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default Custom404;
